<template>
  <b-table striped hover bordered :items="imobiliarias" :fields="fields"/>
</template>

<script>
import {
  BTable
} from 'bootstrap-vue'

export default {
  props: {
    imobiliarias: {
      type: Array
    }
  },
  components: {
    BTable
  },
  data() {
    return {
      fields:[
        { key: 'imobiliaria', label: 'Imobiliária' },
        { key: 'espontaneos', label: 'Espontâneos' },
        { key: 'agendados', label: 'Agendados' },
        { key: 'total', label: 'TOTAL' },
      ]
    }
  },
}
</script>

<style>

</style>