<template>
  <div>
    <b-card>
      <!-- PESQUISA -->
      <Perfil-pesquisa @getData="getData" />
    </b-card>
    <!-- TABS -->
    <b-tabs pills>
      <b-tab title="Visitas" active>
        <b-card-text>
          <b-card>
            <Perfil-all :visitas="visitas" />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab title="Imobiliárias">
        <b-card>
          <Perfil-imobiliarias :imobiliarias="imobiliarias" />
        </b-card>
      </b-tab>
      <b-tab title="Captação">
        <b-card>
          <Perfil-caption :caption="caption" @getData="getData"/>
        </b-card>
      </b-tab>
      <b-tab title="Caixa">
        <b-card>
          <Perfil-caixa :movement="movement"  @getData="getData"/>
        </b-card>
      </b-tab>
      <b-tab title="Gráficos" disabled>
        <b-card-text
          >Macaroon brownie lemon drops croissant marzipan sweet roll macaroon
          lollipop.</b-card-text
        >
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BCardText,
  BCard,
  BButton,
} from "bootstrap-vue";

import PerfilPesquisa from "./PerfilPesquisa.vue";
import PerfilAll from "./PerfilAll.vue";
import PerfilImobiliarias from "./PerfilImobiliarias.vue";
import PerfilCaption from "./PerfilCaption.vue";
import PerfilCaixa from "./PerfilCaixa.vue"

import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";

const now = new Date();

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,

    PerfilPesquisa,
    PerfilAll,
    PerfilImobiliarias,
    PerfilCaption,
    PerfilCaixa
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      visitas: [],
      imobiliarias: [],
      caption: [],
      movement: [],
      firstDay: "",
      lastDay: ""
    };
  },
  methods: {
    async getData(firstDay = this.firstDay, lastDay = this.lastDay) {
      await axios
        .put(`${baseApiUrl}/visitas`, {
          db: this.user.user.produto.db,
          id: this.$route.params.id,
          firstDay,
          lastDay,
        })
        .then((res) => {
          this.setVisitas(res.data);
          this.setImobiliarias(res.data);
          this.setCaption(res.data)
          this.setMovement(firstDay, lastDay)
        });
    },
    setVisitas(visitas) {
      this.visitas = [];
      let espontaneos = 0;
      let agendados = 0;
      for (let i = 0; i < visitas.length; i++) {
        if (visitas[i].canal === "Espontâneo") {
          espontaneos++;
        } else {
          agendados++;
        }
      }
      let total = espontaneos + agendados;
      this.visitas.push({
        espontaneos,
        agendados,
        total,
      });
    },
    setImobiliarias(visitas) {
      this.imobiliarias = [];
      const groupByImob = visitas.reduce((group, product) => {
        const { imobiliaria } = product;
        group[imobiliaria] = group[imobiliaria] ?? [];
        group[imobiliaria].push(product);
        return group;
      }, {});
      for (let imobiliaria in groupByImob) {
        let imobData = {
          imobiliaria,
          espontaneos: 0,
          agendados: 0,
          total: 0,
        };
        for (let i = 0; i < groupByImob[imobiliaria].length; i++) {
          if (groupByImob[imobiliaria][i].canal === "Espontâneo") {
            imobData.espontaneos++;
          } else {
            imobData.agendados++;
          }
        }
        imobData.total = imobData.espontaneos + imobData.agendados;
        this.imobiliarias.push(imobData);
      }
    },
    setCaption(visitas) {
      this.caption = [];
      const groupByImob = visitas.reduce((group, product) => {
        const { captacao } = product;
        group[captacao] = group[captacao] ?? [];
        group[captacao].push(product);
        return group;
      }, {});
      for (let captacao in groupByImob) {
        let imobData = {
          fonte: captacao,
          espontaneos: 0,
          agendados: 0,
          total: 0,
        };
        for (let i = 0; i < groupByImob[captacao].length; i++) {
          if (groupByImob[captacao][i].canal === "Espontâneo") {
            imobData.espontaneos++;
          } else {
            imobData.agendados++;
          }
        }
        imobData.total = imobData.espontaneos + imobData.agendados;
        this.caption.push(imobData);
      }
    },
    setMovement(firstDay, lastDay) {
      axios.post(`${baseApiUrl}/movement`, {
        db: this.user.user.produto.db,
        firstDay,
        lastDay
      }).then(res => {
        if(res.data.success) {
          this.movement = res.data.data
        }
      })
      this.firstDay = firstDay
      this.lastDay = lastDay
    }
  },
  mounted() {},
};
</script>
 

<style>
</style>