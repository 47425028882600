<template>
  <div>
    <b-table striped hover bordered :items="visitas" :fields="fields" />
    <b-progress
      :max="visitas[0].total"
      class="my-3"
      height="2rem"
      v-if="visitas[0]"
    >
      <b-progress-bar
        variant="info"
        :value="visitas[0].espontaneos"
        show-progress
        :label="`Espontâneos [${(
          (visitas[0].espontaneos / visitas[0].total) *
          100
        ).toFixed(0)}%]`"
      />
      <b-progress-bar
        variant="primary"
        :value="visitas[0].agendados"
        show-progress
        :label="`Agendados [${(
          (visitas[0].agendados / visitas[0].total) *
          100
        ).toFixed(0)}%]`"
      />
    </b-progress>
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardText,
} from "bootstrap-vue";
export default {
  components: {
    BTable,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
  },
  props: {
    visitas: {
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        { key: "espontaneos", label: "Espontâneos" },
        { key: "agendados", label: "Agendados" },
        { key: "total", label: "TOTAL" },
      ],
    };
  },
};
</script>

<style>
</style>