<template>
  <b-container>
    <b-row align-h="end" class="justify-content-md-right">
      <b-col class="mb-2">
        <b-button variant="primary" v-b-modal.transition class="float-lg-left">
          Add Transação
        </b-button>
      </b-col>
      <b-col md="4">
        <b-card :border-variant="font">
          <b-media>
            <template #aside>
              <b-avatar size="20" :variant="font" class="p-0">
                <feather-icon size="24" icon="DollarSignIcon" />
              </b-avatar>
            </template>
            <h4 class="font-weight-bolder mb-0" :class="'text-' + font">
              {{
                saldo.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </h4>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table striped hover bordered :items="movement" :fields="fields">
          <template #cell(movement)="data">
            {{
              data.item.movement.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </template>
          <template #cell(final)="data"> R$ {{ data.item.final }},00 </template>
          <template #cell(date)="data">
            {{ setDate(data.item.date) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- MODAL MODAL MODAL MODAL -->
    <b-modal
      id="transition"
      centered
      size="x1"
      title="Nova movimentação"
      hide-footer
    >
      <b-form>
        <b-form-group label="Data*">
          <flat-pickr
            v-model="movitation.date"
            class="form-control"
            :config="configDate"
          />
        </b-form-group>
        <b-form-group label="Discriminação*">
          <b-form-input v-model="movitation.disc" />
        </b-form-group>
        <b-form-group label="Valor*">
          <b-form-input v-model="movitation.movement" type="number" />
        </b-form-group>
        <div class="text-center mt-2">
          <b-button variant="primary" class="mx-2" @click="newMovement('+')">
            Crédito
          </b-button>
          <b-button variant="danger" @click="newMovement('-')">
            Débito
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BMedia,
  BAvatar,
  BTable,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import axios from "axios";
import { baseApiUrl } from "@/global";

import { mapState } from "vuex";

export default {
  props: {
    movement: Array,
  },
  computed: {
    ...mapState(["user"]),
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      font: "danger",
      fields: [
        { key: "date", label: "Data" },
        { key: "disc", label: "Discriminação" },
        { key: "movement", label: "deb/cred" },
      ],
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
      },
      saldo: 0,
      movitation: {
        date: "",
        disc: "",
        movement: 0,
        final: 0,
      },
    };
  },
  methods: {
    async getFinances() {
      await axios
        .post(`${baseApiUrl}/pontos`, {
          db: this.user.user.produto.db,
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.data.caixa) {
            if (res.data.data.caixa > 0) {
              this.font = "primary";
            } else {
              this.font = "danger";
            }
            this.saldo = res.data.data.caixa;
          }
        });
    },
    async newMovement(operation) {
      //fecha modal
      this.$bvModal.hide("transition");

      //Checa se todos os campos estão preenchidos
      if (
        !this.movitation.date ||
        !this.movitation.disc ||
        !this.movitation.movement
      ) {
        this.$bvToast.toast("Preencha os com *", {
          title: "Erro!",
          variant: "danger",
          solid: true,
          appendToast: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      //define se é crédito ou débito e garante que é um number
      if (operation === "-") {
        this.movitation.movement = this.movitation.movement * -1;
      } else {
        this.movitation.movement = this.movitation.movement * 1;
      }

      //define o saldo final da movimentação
      this.saldo = this.saldo + this.movitation.movement
      this.getFinances();

      //registra o DB e os dados na variavel movement
      const movement = {
        db: this.user.user.produto.db,
        movement: this.movitation,
      };
      //SALVA
      await axios
        .put(`${baseApiUrl}/movement`, movement)

        .then((res) => {
          if (!res.data.success) {
            this.$bvToast.toast(res.data.message, {
              title: "Erro!",
              variant: "danger",
              solid: true,
              appendToast: true,
              toaster: "b-toaster-top-center",
            });
            return;
          }
        });

      await axios.put(`${baseApiUrl}/pontos`, {
        db: this.user.user.produto.db,
        ponto: {
          _id: this.$route.params.id,
          caixa: this.saldo,
        },
      })
      //se der certo atualiza a tabela
      this.getFinances();
      this.$emit("getData");

      //Reseta new data
      this.movitation = {
        date: "",
        disc: "",
        movement: 0,
        final: null,
      };
    },
    setDate(date) {
      let data = new Date(date);
      return `${data.getDate()}/${data.getUTCMonth()}/${data.getFullYear()}`;
    },
  },
  mounted() {
    this.getFinances();
  },
};
</script>

<style>
</style>