<template>
  <b-container class="">
    <b-row align-h="end" class="mb-1">
      <b-button variant="primary" v-b-modal.captation>
        + <feather-icon icon="ArchiveIcon" />
      </b-button>
    </b-row>
    <b-row>
      <b-table striped hover bordered :items="caption" :fields="fields" />
    </b-row>

    <!--MODAL -->
    <b-modal
      id="captation"
      centered
      size="lg"
      title="Adicionar Fonte"
      @ok="saveFonte"
    >
      <b-form>
        <b-form-group label="Nome da Fonte">
          <b-form-input placeholder="" v-model="newFonte.label" />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BTable,
  BContainer,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";

export default {
  props: {
    caption: {
      type: Array,
    },
  },
  components: {
    BTable,
    BButton,
    BCol,
    BContainer,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      fields: [
        { key: "fonte", label: "fonte" },
        { key: "espontaneos", label: "Espontâneos" },
        { key: "agendados", label: "Agendados" },
        { key: "total", label: "TOTAL" },
      ],
      newFonte: {
        key: "",
        label: "",
        createdAt: new Date(),
      },
    };
  },
  methods: {
    saveFonte() {
      if (!this.newFonte.label) {
        this.$bvToast.toast("Preencha a fonte", {
          title: "Erro!",
          variant: "danger",
          solid: true,
          appendToast: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }
      this.newFonte.key = this.newFonte.label
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      axios
        .put(`${baseApiUrl}/captacao`, {
          db: this.user.user.produto.db,
          captacao: this.newFonte,
        })
        .then((res) => {
          this.newFonte = {
            key: "",
            label: "",
            createdAt: new Date(),
          };
          if (!res.data.success) {
            this.$bvToast.toast("Agum erro no servidor!", {
              title: "Erro!",
              variant: "danger",
              solid: true,
              appendToast: true,
              toaster: "b-toaster-top-center",
            });
            return;
          }
          this.$bvToast.toast("Fonte Salva com sucesso", {
            title: "Sucesso!",
            variant: "primary",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-center",
          });
        });
    },
    getFonte() {},
  },
};
</script>

<style>
</style>