<template>
  <b-row>
    <b-form-group label="De : " label-for="datee" class="ml-2">
      <flat-pickr
        v-model="firstDay"
        class="form-control"
        :config="configFirstDay"
      />
    </b-form-group>
    <b-form-group label=" à :" label-for="date" class="ml-2">
      <flat-pickr
        v-model="lastDay"
        class="form-control"
        :config="configLastDay"
      />
    </b-form-group>
    <div>
      <!-- PESQUISAR -->
      <b-button
        variant="outline-primary"
        class="btn-icon rounded-circle mt-2 ml-2"
        @click="setDate()"
      >
        <feather-icon icon="SearchIcon" />
      </b-button>

      <b-button
        :variant="setVariant('dia')"
        class="mt-2 ml-3"
        @click="setDay()"
      >
        HOJE
      </b-button>
      <b-button
        :variant="setVariant('semana')"
        class="mt-2 ml-3"
        @click="setWeek()"
      >
        SEMANA
      </b-button>
      <b-button
        :variant="setVariant('mes')"
        class="mt-2 ml-3"
        @click="setMonth()"
      >
        MÊS
      </b-button>
      <b-button
        :variant="setVariant('ano')"
        class="mt-2 ml-3"
        @click="setYear()"
      >
        ANO
      </b-button>
      <b-button
        variant="gradient-warning"
        class="btn-icon mt-2 ml-3 mr-1"
        v-b-modal.register
      >
        <feather-icon icon="UserPlusIcon" />
      </b-button>
    </div>
    <!-- MODAL REGISTRO NOVA VISITA -->
    <b-modal
      id="register"
      centered
      size="lg"
      title="Nova Visita"
      @ok="saveVisita"
    >
      <b-form>
        <!-- DATA -->
        <b-form-group label="Data da visita*" label-for="date">
          <flat-pickr
            v-model="visita.date"
            class="form-control"
            :config="config"
          />
        </b-form-group>
        <!-- NOME DO CLIENTE -->
        <b-form-group label="Nome do Cliente" label-for="nome">
          <b-form-input
            id="nome"
            placeholder="Nome do cliente aqui"
            v-model="visita.cliente"
          />
        </b-form-group>
        <!-- TELEFONE DO CLIENTE -->
        <b-form-group label="Telefone do Cliente" label-for="telefone">
          <b-form-input
            id="telefone"
            placeholder="Nome do cliente aqui"
            v-mask="'(XX) XXXXX-XXXX'"
            v-model="visita.phone"
          />
        </b-form-group>
        <!-- IMOBILIÁRIA -->
        <b-form-group label="Imobiliária*" label-for="imobiliaria">
          <v-select
            id="imobiliaria"
            v-model="visita.imobiliaria"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="imobiliarias"
          />
        </b-form-group>
        <!-- FONTE DE CAPTAÇÃO -->
        <b-form-group label="Captação" label-for="caption">
          <v-select
            id="caption"
            v-model="visita.captacao"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="captacao"
          />
        </b-form-group>

        <!-- Canal -->
        <b-form-group label="Canal" label-for="canal">
          <v-select
            id="canal"
            v-model="visita.canal"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="canal"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BModal,
  BForm,
  BFormInput,
  VBModal,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import Ripple from "vue-ripple-directive";

import { mask } from "vue-the-mask";
import vSelect from "vue-select";

import axios from "axios";
import { baseApiUrl, userKey } from "@/global";
import { mapState } from "vuex";

export default {
  directives: {
    Ripple,
    mask,
    "b-modal": VBModal,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BModal,
    BForm,
    BFormInput,
    flatPickr,
    Ripple,
    vSelect,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      firstDay: "",
      lastDay: "",
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
      },
      configFirstDay: {
        wrap: true,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
        maxDate: this.lastDay,
      },
      configLastDay: {
        wrap: true,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
        minDate: this.firstDay,
      },
      variant: "",
      imobiliarias: [],
      captacao: [],
      canal: ["Espontâneo", "Agendamento"],
      visita: {},
    };
  },
  methods: {
    setMonth() {
      const now = new Date();
      this.firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      this.lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.variant = "mes";
      this.$emit("getData", this.firstDay, this.lastDay);
    },
    setWeek() {
      const now = new Date();
      var primeiro = now.getDate() - now.getDay() + 1;
      this.firstDay = new Date(now.getFullYear(), now.getMonth(), primeiro);
      this.lastDay = new Date(now.getFullYear(), now.getMonth(), primeiro + 6);
      this.variant = "semana";
      this.$emit("getData", this.firstDay, this.lastDay);
    },
    setYear() {
      const now = new Date();
      this.firstDay = new Date(now.getFullYear(), 0, 1);
      this.lastDay = new Date(now.getFullYear(), 11, 31);
      this.variant = "ano";
      this.$emit("getData", this.firstDay, this.lastDay);
    },
    setDay() {
      const now = new Date();
      this.firstDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      this.lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      this.variant = "dia";
      this.$emit("getData", this.firstDay, this.lastDay);
    },

    setVariant(button) {
      if (button === this.variant) {
        return "primary";
      }
      return "outline-primary";
    },
    setDate() {
      this.variant = "";
      this.firstDay = Date.parse(this.firstDay);
      this.lastDay = Date.parse(this.lastDay);
      this.$emit("getData", this.firstDay, this.lastDay);
    },
    saveVisita() {
      if (!this.visita.date || !this.visita.imobiliaria || !this.visita.canal || !this.visita.captacao) {
        this.$bvToast.toast("Preencha os campos obrigatórios", {
          title: "Opa !",
          variant: "danger",
          solid: true,
          appendToast: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }
      this.visita.date = Date.parse(this.visita.date);
      this.visita.ponto_id = this.$route.params.id;
      axios
        .post(`${baseApiUrl}/visitas`, {
          db: this.user.user.produto.db,
          visita: this.visita,
        })
        .then((res) => {
          if (res.success) {
            this.$bvToast.toast("Agendamento concluído com sucesso! ", {
              title: "Sucesso !",
              variant: "primary",
              solid: true,
            });
          }
        });
      this.visita = {}
      this.$emit("getData", this.firstDay, this.lastDay);
    },
    async getData() {
      await axios
        .post(`${baseApiUrl}/imobiliarias`, {
          db: this.user.user.produto.db,
        })
        .then((res) => {
          if (res.data.success) {
            for (let i = 0; res.data.data.length > i; i++) {
              this.imobiliarias.push(res.data.data[i].name);
            }
          }
        });
      await axios.post(`${baseApiUrl}/captacao`, {
        db: this.user.user.produto.db
      }).then(res => {
        for (let i = 0; res.data.data.length > i; i++) {
          this.captacao.push(res.data.data[i].label)
        }
      })
    },
  },
  watch: {
    firstDay: function () {
      this.configLastDay = {
        wrap: true,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
        minDate: this.firstDay,
      };
    },
    lastDay: function () {
      this.configFirstDay = {
        wrap: true,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "m/d/Y",
        locale: Portuguese,
        maxDate: this.lastDay,
      };
    },
  },
  mounted() {
    this.setMonth();
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>